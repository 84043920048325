import React, { Component } from 'react';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid } from 'material-ui';
import ItemGrid from 'components/Grid/ItemGrid';
import Button from 'components/CustomButtons/Button';
import Cars from './Cars';
import { getArrays } from 'utils';
import { setNotify, clearNotify } from 'store/notify';
import Calendar from 'react-calendar';
import { Close } from '@material-ui/icons';
import Select from 'components/Select';
import SelectInput from 'components/SelectInput';
const today = moment().utc().startOf('day');
const tomorrow = today.clone().add(1, 'd');
const daysTitles = {
  [today.unix()]: 'Today',
  [tomorrow.unix()]: 'Tomorrow'
};

class LogisticDashboard extends Component {
  constructor(props) {
    super(props);
    const deliveries = {
      [today.unix()]: {},
      [tomorrow.unix()]: {}
    };
    this.state = {
      isMobile: window.innerWidth <= 800,
      today,
      deliveries,
      activeDay: today.unix(),
      activePartner:'0',
      customDate: null,
      showCalendar: false,
      filters: {},
      calendarLeft: 0,
      activeSlot: 0,
      activeWindow: 0,
      bagVisitsInit: [],
      priorities: [],
      routes: { ...deliveries },
      clientsList: {},
      todaySlotsIds: [],
      todaySlotsWithPartner: [],
      driversList: {},
      bagVisits: [],
      deliveryVisits: {},
      used: {
        [today.unix()]: [],
        [tomorrow.unix()]: []
      },
      usedRoutes:{},
      notUsedRoutes: {}
    };
    this.getUsersList(today.unix());
  }

  getUsersList(date, activeSlot) {
    this.props.socket.emit('logistics', {
      type: 'list', 
      data: { date, activeSlot,isDeliveryPartner:true }
    });
  }

  getRoutes(date) {
    this.props.socket.emit('logistics', {
      type: 'get',
      data: { date }
    });
  }

  listener = action => {
    if (this.LogisticDashboardRef) {
      const { type, data } = action;
      console.log('+++++++++++++++++++++++++++');
      console.log(data);
      console.log('+++++++++++++++++++++++++++');
      switch (type) {
        case 'listOk':
          // this.getRoutes(this.state.activeDay);
          const { deliveries, bagVisits, activeSlot: _activeSlot } = data;
          const allSlots = Object.values(deliveries)[0];
          const activeSlot = _activeSlot || +Object.keys(allSlots || {})[0] || 0;
          this.setState({ ...data, activeSlot, bagVisitsInit: [...bagVisits] });
          break;
        case 'setOk':
          console.log(data);
          break;
        case 'setPriorityOk':
          const { priority, value } = data;
          const oldPriorities = [...this.state.priorities];
          const newPriorities = value ? [...oldPriorities, priority] : oldPriorities.filter(el => {
            const { date, driver, window } = el;
            return priority.date !== date && priority.driver !== driver && priority.window !== window;
          });
          this.setState({ priorities: newPriorities });
          break;
        case 'getOk':
          const { routes, priorities,usedRoutes } = data;
    
          const used = Object.keys(routes).reduce((acc, date) => {
            const dateInfo = Object.values(routes[date]).reduce((acc, times) => {
              return [
                ...acc,
                ...Object.values(times).reduce((acc, clients) => [...acc, ...clients.filter(el => !acc.includes(el))], []).filter(el => !acc.includes(el))
              ];
            }, []);
            return { ...acc, [date]: dateInfo };
          }, {});
          const newBagVisits = this.state.bagVisits.filter(el => !(used[this.state.activeDay].includes(el)));
          this.setState({ routes, used, priorities, usedRoutes, bagVisits: newBagVisits });
          break;
        case 'listErr':
          console.error(data);
          break;
        case 'setPriorityErr':
          const { message, code } = data;
          if (code === 404) {
            this.showNotification(`${message} Please, save route first`, 'warning');
          }
          console.error(data);
          break;
        case 'setErr':
          console.error(data);
          break;
      } 
    }
  };

  componentWillMount() {
    this.props.socket.on('logistics', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('logistics', this.listener);
  }

  componentDidMount() {
    const { socket, dataArray } = this.props;
    const types = ['timesList', 'deliveryTime', 'areasList', 'transportTypes', 'emirate', 'slotsList','deliveryPartner','deliveryPartnerFull'].filter(el => !(el in dataArray));
    if (types.length) {
      getArrays(socket, types);
    }
  }

  

  setWindow = activeWindow => this.setState({ activeWindow });

  

  

  showNotification(message, color = 'info', place = 'tc', time = 5000) {
    this.props.setNotify({ message, place, color });
    setTimeout(() => this.props.clearNotify(), time);
  }

  getPosition = () => {
    if (this.ButtonsHolderRef) {
      const butt = [...this.ButtonsHolderRef.querySelectorAll('button')].reverse()[0];
      return butt.offsetLeft + 'px';
    }
  };
  applyFilter = (filters = {}) => {
    const { periodFrom, periodTo } = filters;
    // const dates = this.getDatesFromPeriod(periodFrom, periodTo, '[]');
    const { filters: oldFilters } = this.state;
    if (periodFrom !== oldFilters.periodFrom || periodTo !== oldFilters.periodTo ) {
      this.setState({ filters: { periodFrom, periodTo} });
    }
    window.open(
      'https://basiligo:basiligo2019!@report.basiligo.ae/admin/site/logisticcsvfilter?date='+periodFrom+'&resumedate='+periodTo,
      '_blank'
    );
  };
  render() {
    const { state, props, setWindow } = this;
    const { deliveries, activeDay, activeSlot, deliveryVisits: allDeliveryVisits,
      used: allUsed, bagVisits, bagVisitsInit, clientsList, customDate, showCalendar, calendarLeft ,todaySlotsIds,usedRoutes,notUsedRoutes, activePartner ,todaySlotsWithPartner} = state;
    // const used = allUsed[activeDay] || [];
    const used = usedRoutes[activeDay]?usedRoutes[activeDay][activeSlot]||[]: [];
    const deliveryVisits = allDeliveryVisits[activeDay] || [];
    const { dataArray } = props;
    // console.log(dataArray);
    const { timesList, slotsList, areasList: areas, transportTypes, emirate: emirates,deliveryPartner, deliveryPartnerFull } = dataArray;
    const windows = (timesList || []).reduce((acc, { id, name, slot_id }) => +slot_id === activeSlot ? { ...acc, [+id]: name } : acc, {});
    const slots = (slotsList || []).reduce((acc, { id, name }) => ({ ...acc, [+id]: name }), {});
    const currentDayData = deliveries[activeDay] || {};
    var totalDelivery = 0;
    const currentClients = currentDayData[activeSlot];
    // console.log(currentClients);
    const currentDaySlots = Object.keys(currentDayData);
    const currentDayClients = currentDaySlots.reduce((acc, cur) => ([...acc, ...Object.keys(currentDayData[cur])]), []).map(el => +el);
    const notUsed = [...deliveryVisits, ...bagVisits].filter(el => !used.includes(el));

    var deliveryParList = {0: "All"};
    Object.assign( deliveryParList ,deliveryPartner); 

    const buttons = Object.keys(deliveries || {}).slice(0, 2).map((date, idx) => {
      return <Button key={idx} color={(+date === +activeDay) ? 'darkBlue' : 'white'} onClick={() => this.setState({ activeDay: +date })}>
        {daysTitles[date] || moment.unix(date).utc().format('LLL')}
      </Button>;
    });
    
    const todaySlots = (activePartner==0)?todaySlotsIds[activeDay]:todaySlotsWithPartner[activeDay][activePartner];
    const buttonsFilters =activeDay && todaySlotsIds[activeDay] && todaySlotsIds[activeDay].filter(function (el) {
       if(activePartner==0){
           return true;
       }else{
          return (todaySlots!=undefined)?Array.isArray(todaySlots)?todaySlots.includes(el):Object.values(todaySlots).includes(el):false;
       }
     }).map((slotId, idx) => {
      const slotBags = bagVisitsInit.filter(el => clientsList[el] && clientsList[el][activeDay][+slotId]!= undefined);
      const planned = used.filter(el => clientsList[el] && clientsList[el][activeDay][+slotId]!= undefined).length;
      const notPlanned = notUsed.filter(el => (el in (currentDayData[+slotId] || {})) || slotBags.includes(el)).length;
      let totalSlotDelivery =(activePartner==0)?planned+notPlanned:Object.keys(clientsList[activePartner][activeDay][+slotId]).length;
      totalDelivery = totalDelivery+totalSlotDelivery;
      return <Button
        key={idx}
        color={+slotId === +activeSlot ? 'darkBlue' : 'white'}
        onClick={() => this.setState({ activeSlot: +slotId })}
      >
        <p>{(slots || {})[+slotId]}</p>
        <span className='deliv'>
          Deliveries: {totalSlotDelivery}
        </span>
      </Button>;
    });
    const carsProps = {
      ...{ windows, slots, areas, transportTypes, emirates, todaySlotsIds, timesList, deliveryParList,deliveryPartnerFull,activePartner,notUsedRoutes,usedRoutes },
      ...{ currentClients, currentDaySlots, deliveryVisits, notUsed, bagVisitsInit, used, setWindow }
    };
    return <Grid container ref={el => (this.LogisticDashboardRef = el)}>
      <div className='tabsCustom' ref={el => (this.ButtonsHolderRef = el)}>
        
        <ItemGrid md={12}>
          {buttons}
          <Button color={(+customDate === +activeDay) ? 'darkBlue' : 'white'} onClick={() => this.setState({ showCalendar: true, calendarLeft: this.getPosition() })}>
            Select Date
          </Button>
          <div className="delivery-partner-select">
            <SelectInput
                labelText='Delivery Partner'
                name='delivery_partner_id'
                formControlProps={{
                  fullWidth: true,
                  className:''
                }}
                value={activePartner} 
                items={deliveryParList || {}}
                inputProps={{
                  onChange: e => this.setState({ activePartner: e.target.value})
                }}
              /></div>
          <Button color="warning" onClick={() => {
            const {activeDay} = this.state;
            window.open(
              'https://basiligo:basiligo2019!@report.basiligo.ae/admin/site/logistic?date='+activeDay+'&partner='+activePartner,
              '_blank'
            );
           } }> 
            Web Preview
          </Button> 
          <Button color="danger" onClick={() => {
            const {activeDay} = this.state;
            window.open(
              'https://basiligo:basiligo2019!@report.basiligo.ae/admin/site/logisticpdf?date='+activeDay+'&partner='+activePartner,
              '_blank'
            );
           } }>
            Export PDF
          </Button>
         
           <Button color="success" onClick={() => {
            const {activeDay} = this.state;
            window.open(
              'https://basiligo:basiligo2019!@report.basiligo.ae/admin/site/tags?date='+activeDay,
              '_blank'
            );
           } }> 
            Tags Web Preview
          </Button>
          <Button color="danger" onClick={() => {
            const {activeDay} = this.state;
            window.open(
              'https://basiligo:basiligo2019!@report.basiligo.ae/admin/site/tagspdf?date='+activeDay,
              '_blank'
            );
           } }>
            Tags Export PDF
          </Button>
          <Button color="success" onClick={() => {
            const {activeDay} = this.state;
            window.open(
              'https://basiligo:basiligo2019!@report.basiligo.ae/admin/site/logisticcsv?date='+activeDay+'&partner='+activePartner,
              '_blank'
            );
           } }>
            CSV Export
          </Button>
          <Button color="success" onClick={() => {
            const {activeDay} = this.state;
            window.open(
              'http://basiligo:basiligo2019!@healthyme.basiligo.ae/admin/site/logisticxls?date='+activeDay,
              '_blank'
            );
           } }>
            Logx xlx export
          </Button>
          {/* <Button color="danger" onClick={() => {
            const {activeDay} = this.state;
            window.open(
              'https://basiligo:basiligo2019!@report.basiligo.ae/admin/site/leebhacsv?date='+activeDay,
              '_blank'
            );
           } }>
            Leebha CSV Export
          </Button> */}
          {false && <Button color='success' onClick={() => this.saveRoutes()} pullRight>Submit Routes</Button>}
        </ItemGrid>
      </div>
      <div className='afterTabsCustom logistic'>
        <ItemGrid md={12}>
          <div className='package-board'>
            <div className='top-position styled all'>
              <div className='buttonspart'>
                {buttonsFilters} 
              </div>
              <div className='totalpart'>
                <div>Deliveries: total {totalDelivery}</div>
              </div>
            </div>
            {(windows && areas && transportTypes && emirates) && <Cars
              {...{ ...state, ...carsProps }}
            />}
          </div>
        </ItemGrid>
      </div>
      {showCalendar && <div className={'logistic-calendar'} style={{ left: calendarLeft }}>
        <div className={'modal-close-button'} onClick={() => this.setState({ showCalendar: false })}><Close /></div>
        <Calendar
          value={customDate || new Date()}
          onChange={date => {
            const isoDate = `${date.getFullYear()}-${('' + (date.getMonth() + 1)).padStart(2, '0')}-${('' + (date.getDate())).padStart(2, '0')}T00:00:00.000Z`;
            const unix = moment(isoDate).unix();
            this.setState({ customDate: date, activeDay: unix, showCalendar: false });
            this.getUsersList(unix);
          }}
          minDate={new Date(today.unix() * 1000)}
        />
      </div>}
    </Grid>;
  }
}

LogisticDashboard.propTypes = {
  socket: PropTypes.object,
  dataArray: PropTypes.object,
  setNotify: PropTypes.func,
  clearNotify: PropTypes.func,
  location: PropTypes.object.isRequired
};

const props = state => ({
  dataArray: state.dataArray
});

const actions = dispatch => ({
  setNotify: obj => dispatch(setNotify(obj)),
  clearNotify: () => dispatch(clearNotify())
});

export default socketConnect(connect(props, actions)(LogisticDashboard));
