import React, { Component,Fragment} from 'react';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import moment from 'moment';
class OrderLog extends Component {
  
  constructor(props) {
    super(props);
    const { user_id } = props;
    this.state = {
      orderlogs: [],
      isToggleOn:false
    };
    this.getActiveOrderLogs(user_id); 
  }
  
  getActiveOrderLogs = user_id => this.props.socket.emit('order', { type: 'getActiveOrderLogs', data: { user_id } });

  listener = action => {
    const { type, data } = action;
    if (type === 'getActiveOrderLogsOk') {
      this.setState({orderlogs:data.orderlogs});
      console.log(this.state);
    } else if (type === 'getActiveOrderLogs') {
      console.err(data.message);
    }
  };

  componentWillMount() {
    this.props.socket.on('order', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('order', this.listener);
  }

  componentWillReceiveProps(next) {
    if (this.OrderLogRef && next.user_id !== this.props.user_id) {
      this.getActiveOrderLogs(next.user_id);
    }
  }
 
  render() {
    const { orderlogs,isToggleOn } = this.state;
    const { user_id ,dataArray} = this.props;
    const {products,typeList,slotsList}=dataArray;
    var logs = [];
    //console.log(orderlogs);
    console.log(dataArray);
    var message='';
    
    if (orderlogs) {
      Object.keys(orderlogs).map( (el, idx) => {
        orderlogs[el].map( (el1, idx) => {
          message='';
          var data=(el1.data!=null)?JSON.parse(el1.data):[];
          switch(el1.action){
            case 'saveEditPlan':
            case 'modifyPlan':
              if(data.new_data!=undefined&&data.new_data.length>0){
                data.new_data.map((nd, ni) => {
                  console.log(nd);
                  // console.log(data.old_data[ni]);
                  if(data.old_data[ni]!=undefined&&data.old_data[ni].dish_id!=undefined){
                    if(nd.date!=undefined){
                        var title_name = '';
                        var old_title_name = '';
                        var new_titile_name = '';
                        
                        if(typeList && typeList[nd.mealType_id].hasOwnProperty('title')){
                           title_name = typeList[nd.mealType_id].title;
                        }
                        if(products[data.old_data[ni].dish_id] && products[data.old_data[ni].dish_id].hasOwnProperty('title')){
                           old_title_name = products[data.old_data[ni].dish_id].title;
                        }
                        if(products[nd.dish_id] && products[nd.dish_id].hasOwnProperty('title')){
                           new_titile_name = products[nd.dish_id].title;
                        }
                        message+="Date: "+moment.unix(nd.date).format('DD MMM, YYYY')+',Meal Type:"'+title_name+'", Old dish:"'+old_title_name+'", New Dish:"'+new_titile_name+'".\n';
                     }else if(nd.dish_id!=undefined){
                        message+'From dish "'+products[data.old_data[ni].dish_id].title+'" to "'+products[nd.dish_id].title+'".\n';
                     }
                  }else if(nd.cutlery!=undefined){
                    if(nd.cutlery!=data.old_data[ni].cutlery){
                       message+='Changed the cutlery from '+data.old_data[ni].cutlery+' to '+nd.cutlery;
                    }
                    if(nd.slot_id!=data.old_data[ni].slot_id){
                      message+='Changed the slot from '+slotsList[data.old_data[ni].slot_id]+' to '+slotsList[nd.cutlery.slot_id];
                   }
                  }
                });
              }
            break;
          }
        logs.push(
          <li>
          <a href="javascript:void(0);" >{el1.action} by {el1.modified_by} : {el1.user_type} </a>
        
          <a href="#" className="float-right">{moment.unix(el1.created_date).format('DD MMM, YYYY h:mm:ss A')}</a>
          <p>{el1.description}</p>
          <Fragment>
            <p>{message}</p>
          </Fragment>
          
        </li>
            )
            });
      });
    }

    return (
      <div>
      <ul className="timeline">
        {logs}
      </ul>
      
      </div>
    );
  }
}

OrderLog.propTypes = {
  socket: PropTypes.object,
  orderlogs: PropTypes.object,
  user_id: PropTypes.number.isRequired
};
const props = state => ({
  products: state.dataArray.products
});
export default socketConnect(connect(props, null)(OrderLog));
